import styled from "styled-components";
import { colors } from "../abstract/variables";
import { media } from "../base/media-queries";

export const StyledBreadcrumb = styled.div`
  .breadcrumb {
    padding: 1rem 0;
    margin-bottom: 2px;
    box-shadow: 0 4px 2px -2px #c1caca;
    background-color: ${colors.white};

    ${media.mobile} {
      padding: 1rem 2rem;
    }

    &__item {
      color: ${colors.red};
      font-weight: 500;
      display: inline-block;

      &.active {
        color: ${colors.grey};
      }

      &:not(:last-child) {
        &::after {
          content: "/";
          margin: 0 0.5rem;
        }
      }
    }
  }
`;
