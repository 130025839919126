import styled from "styled-components";
import { colors } from "../abstract/variables";

export const StyledButtonPrimay = styled.div`
  .btn-primary,
  .btn-primary:link,
  .btn-primary:visited,
  .ui.button.btn-primary,
  .ui.button.btn-primary:link,
  .ui.button.btn-primary:visited {
    background-color: ${colors.red};
    color: ${colors.white};
    cursor: pointer;
    border: none;
    font-size: 1.8rem;
    border-radius: 0.6rem;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    padding: 1.5rem 2.5rem;
    text-decoration: none;
    transition: all 0.3s;
    text-align: center;
    font-weight: 300;

    &:hover {
      background-color: ${colors.red};
      color: ${colors.white};
    }
  }
`;
