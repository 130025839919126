import styled from "styled-components";
import { colors } from "../abstract/variables";
import { media } from "../base/media-queries";

export const StyledCard = styled.div`
  .card {
    background-color: ${colors.white};
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 1.5rem;
    min-height: 36rem;
    box-shadow: 0rem 0.1rem 1rem 0rem rgba(0, 0, 0, 0.25);
    width: calc((100% - 2 * 2.9rem) / 3);
    margin: 0 0 2.9rem 2.8rem;
    float: left;

    ${media.ipad} {
      width: calc((100% - 2 * 2.9rem) / 2);
    }

    ${media.mobile} {
      width: 90%;
      margin-left: 0;
      max-width: 50rem;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;

      ${media.ipad} {
        margin-left: 2.9rem;
      }

      ${media.mobile} {
        margin-left: 0;
      }
    }

    &:first-child,
    &:nth-child(2n + 1) {
      ${media.ipad} {
        margin-left: 0;
      }
    }

    &__header {
      color: ${colors.grey3};
      font-size: 1.4rem;
    }

    &__title {
      color: ${colors.red};
      font-size: 2.6rem;
      font-weight: 300;
      margin: 0.5rem 0 2rem 0;
      line-height: 1.2;
    }

    &__image {
      width: auto;
      height: 20rem;

      &--DustExtractors {
        min-height: 25rem;
        margin: 0;
      }

      &--MobilDustExtractors {
        min-height: 30rem;
      }
    }

    &__types {
      text-align: center;
    }

    &__logo {
      height: 4rem;
      margin: 0 auto;
      margin-top: auto;

      ${media.ipad} {
        margin-top: 0;
      }
    }
  }
`;
