import styled from "styled-components";
import { colors } from "../../../styles/abstract/variables";

export const StyledContact = styled.div`
  .contact__form {
    background-color: #f3e3e3;
    padding: 8rem 0;

    .container {
      max-width: 99rem;
    }

    a {
      &,
      &:link,
      &:visited {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__title {
      color: ${colors.red};
      text-align: center;
      font-size: 3.6rem;
      margin-bottom: 7rem;
    }

    &__info {
      text-align: left;
      font-size: 1.6rem;
      font-weight: 300;
      margin-bottom: 2rem;
    }

    &__checkboxes {
      font-size: 1.6rem;
      font-weight: 400;

      .checkbox {
        padding-top: 2.5rem;

        &__label {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;

          input[type="checkbox"] {
            left: -9999rem;
            position: absolute;
          }

          input[type="checkbox"]:checked ~ span::after {
            color: ${colors.red};
          }

          span {
            margin-left: 4rem;
            text-align: left;

            &::after {
              align-items: center;
              background-color: rgb(255, 255, 255);
              content: "\f00c";
              color: transparent;
              display: inline-flex;
              font-family: FontAwesome;
              height: 3rem;
              justify-content: center;
              left: 0px;
              position: absolute;
              top: 50%;
              transform: translateY(-59%);
              width: 3rem;
            }
          }
        }
      }
    }

    &__submit {
      padding-top: 3rem;
      display: flex;
      justify-content: center;
      font-size: 2.4rem;

      .btn-primary {
        font-weight: 300;
      }
    }

    .field {
      input,
      textarea {
        font-size: 1.6rem;
        outline: none;
        padding: 1.5rem 1.5rem;

        &.contact__form__input--red {
          color: ${colors.red};
        }
      }
    }
  }
`;
