import React from "react";
import { Placeholder } from "semantic-ui-react";

export const MachineryPagePlaceholder = () => {
  return (
    <Placeholder style={{ minHeight: 600, marginTop: 60, marginBottom: 60 }} fluid>
      <Placeholder.Header image />
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
      <Placeholder.Image />
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  );
};
