export const typeToName: { [key: string]: string } = {
  "4221 ONER": "ALURANGER4221ONER",
  "6321 ONER": "ALURANGER6321ONER",
  "4221 VG": "ALURANGER4221VGROOVE",
  "6321 VG": "ALURANGER6321VGROOVE",
  "10121 VG": "ALURANGER10121VGROOVE",
  "ALU LOADER": "ALULOADER",
  "ALU BENDER": "ALUBENDER",
  "ALU EDGER": "ALUEDGER",
  "E 450 PM": "E450PM",
  "E 550": "E550",
  "E 550 PM": "E550PM",
  "E 550 CR": "E550CR",
  "E 550 PMCR": "E550PMCR",
  "160": "PUE160",
  "200": "PUE200",
  "250": "PUE250",
  "350": "PUE350",
  "350+": "PUE350plus",
  "MOBIL 125": "MOBIL125",
};
