import styled from "styled-components";
import { colors } from "../../../styles/abstract/variables";

export const StyledDisclaimer = styled.div`
  .disclaimer {
    padding-bottom: 8rem;

    &__title {
      margin-top: 8rem;
      text-align: center;
      font-size: 3.6rem;
      font-weight: 400;
      color: ${colors.red};
    }

    &__subtitle {
      margin-top: 4rem;
      margin-bottom: 2rem;
      font-size: 2.4rem;
      color: ${colors.red};
      font-weight: 300;
    }
  }
`;
