import React from "react";
import { StyledFooter } from "./StyledFooter";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <StyledFooter>
      <footer>
        <div className="footer">
          <div className="container">
            <div className="footer__items">
              <div className="footer__item">
                <b>Showroom</b>
                <p>
                  <span>13 Beach Avenue</span>
                  <span>Mordialloc, 3195, VIC</span>
                </p>
              </div>
              <div className="footer__item">
                <b>Via Mail</b>
                <p>
                  <span>PO Box 7066 St Kilda Rd</span>
                  <span>Melbourne Victoria 3004</span>
                </p>
              </div>
              <div className="footer__item footer__item__contact">
                <p>
                  <b>P</b>1300 072 403
                  <br />
                  <b>E</b>
                  <a href="mailto:enquiries@ausmach.com.au">enquiries@ausmach.com.au</a>
                </p>
              </div>
              <div className="footer__item social">
                <div className="social__items">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/AUSMACH/"
                  >
                    <img src="/images/icons/facebook-icon-new.png" alt="fb icon" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/16238823/"
                  >
                    <img src="/images/icons/LinkedIn-icon-new.png" alt="linkedin icon" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/AUSMACHAUS"
                  >
                    <img src="/images/icons/Twitter-icon-new.png" alt="twitter icon" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/ausmachaus/"
                  >
                    <img src="/images/icons/Instagram-icon-new.png" alt="ins icon" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UCWk-WO7yLl0Wk81746wdOwA"
                  >
                    <img src="/images/icons/Youtube-icon-new.png" alt="youtube icon" />
                  </a>
                </div>
              </div>
            </div>

            <div className="footer__copyright">
              <div className="footer__copyright__content">
                <span>&copy; {new Date().getFullYear()} AUSMACH</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://blog.leaseplusgroup.com.au/careers/"
                >
                  Careers
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://leaseplusgroup.com.au/Privacy"
                >
                  Privacy Policy
                </a>
                <Link to="/DisclaimerAndLegal">Disclaimer &amp; Legal</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </StyledFooter>
  );
};
