import React from "react";
import { Grid, Placeholder, Segment } from "semantic-ui-react";

export const HomeCardsPlaceholder = () => {
  return (
    <Grid style={{ padding: 30 }} columns={3} stackable>
      <Grid.Column>
        <Segment style={{ minHeight: 280, minWidth: 200 }} raised>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Image rectangular />
            </Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Segment>
      </Grid.Column>

      <Grid.Column>
        <Segment style={{ minHeight: 280, minWidth: 200 }} raised>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Image rectangular />
            </Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Segment>
      </Grid.Column>

      <Grid.Column>
        <Segment style={{ minHeight: 280, minWidth: 200 }} raised>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Image rectangular />
            </Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};
