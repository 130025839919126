import React, { useState, useEffect, Fragment } from "react";

import * as H from "history";
import { StyledHeader } from "./StyledHeader";
import { Link } from "react-router-dom";
import { DisplayMobile } from "../../../styles/base/utilities";
import MachineryBySubCategory from "../../../models/MachineryBySubCategory";
import agent from "../../../api/agent";
import { Loader } from "semantic-ui-react";
import { HeaderLeftbox } from "./HeaderLeftbox";
import { HeaderContent } from "./HeaderContent";

interface Props {
  isHome: boolean;
  history: H.History;
  isOpenMenu: boolean;
  setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header: React.FC<Props> = ({ isHome, history, isOpenMenu, setIsOpenMenu }) => {
  const [machineryBySubCategory, setMachineyBySubcategory] = useState<MachineryBySubCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [isCollapse, setIsCollapse] = useState(false);

  useEffect(() => {
    setLoading(true);
    agent.Machineries.machineryBySubCategory()
      .then((response) => {
        setMachineyBySubcategory(response);
      })
      .then(() => setLoading(false))
      .catch(() => history.push("/notfound"));
  }, [history]);

  return (
    <StyledHeader isOpenMenu={isOpenMenu} isHome={isHome}>
      <header className="header">
        <div className="header__top">
          <HeaderLeftbox isHome={isHome} />
          <DisplayMobile>
            <div className="u-display-mobile">
              {loading ? (
                <Loader active inverted inline size="large" />
              ) : (
                <Fragment>
                  {isOpenMenu ? (
                    <button
                      className="header__menu__close"
                      onClick={() => setIsOpenMenu(false)}
                    ></button>
                  ) : (
                    <div className="header__top__right-box">
                      <i
                        onClick={() => setIsOpenMenu(true)}
                        className="fa fa-bars"
                        aria-hidden="true"
                      ></i>
                    </div>
                  )}
                  <div className="header__menu">
                    <div className="header__menu__container">
                      <div className="header__menu__list">
                        <Link
                          onClick={() => setIsOpenMenu(false)}
                          to={"/"}
                          className="header__menu__list--item"
                        >
                          Home
                        </Link>
                        <div className="line"></div>
                        <div className={`header__menu__list--item ${isCollapse ? "active" : ""}`}>
                          Products
                          <i
                            onClick={() => setIsCollapse(!isCollapse)}
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div className={`header__menu__products ${isCollapse ? "active" : ""}`}>
                          {machineryBySubCategory.map((m, i) => (
                            <Link
                              to={`/machinery/${m.subCategory.replace(/\s/g, "")}`}
                              onClick={() => setIsOpenMenu(false)}
                              key={i}
                              className={`header__menu__products--item header__menu__products--item--${m.subCategory.replace(
                                /\s/g,
                                "",
                              )}`}
                            >
                              <div className="header__menu__products--item__title">
                                {m.subCategory}
                              </div>
                              <img
                                src={m.subCategoryImageUrl}
                                alt={m.subCategory}
                                className={`header__menu__products--item__image header__menu__products--item__image--${m.subCategory.replace(
                                  /\s/g,
                                  "",
                                )}`}
                              />
                              <div className="header__menu__products--item__footer">LEARN MORE</div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </DisplayMobile>
        </div>
        <HeaderContent />
      </header>
    </StyledHeader>
  );
};
