/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef } from "react";
import { Form, Button } from "semantic-ui-react";
import Machinery from "../../../models/Machinery";
import FormValues from "../../../models/FormValues";
import { Form as FinalForm, Field } from "react-final-form";
import { TextInput } from "../Form/TextInput/TextInput";
import { TextAreaInput } from "../Form/TextAreaInput/TextAreaInput";
import agent from "../../../api/agent";
import { LoadingComponent } from "../../layouts/LoadingComponent/LoadingComponent";
import { dialogBox } from "../../../services/DialogBox";
import { FormApi } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import ReCAPTCHA from "react-google-recaptcha";
import { StyledContact } from "./StyledContact";
import { StyledGrid } from "../../../styles/components/StyledGrid";
import { StyledButtonPrimay } from "../../../styles/components/StyledButton";

interface Props {
  machinery: Machinery;
}

interface Values {
  machinery: Machinery;
  machineryType: string;
  machineryTitle: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactNumber: string;
  companyName: string;
  state: string;
  message?: string;
  hasRead: boolean;
}

const validate = combineValidators({
  firstName: isRequired({ message: "First name is required" }),
  lastName: isRequired({ message: "Last name is required" }),
  emailAddress: isRequired({ message: "Email address is required" }),
  contactNumber: isRequired({ message: "Contact number is required" }),
  companyName: isRequired({ message: "Company name is required" }),
  state: isRequired({ message: "State is required" }),
  hasRead: isRequired("Has read"),
});

export const ContactUs: React.FC<Props> = ({ machinery }) => {
  const [submitting, setSubmitting] = useState(false);
  const [isNotRobot, setIsNotRobot] = useState(false);

  const fieldList: any = [
    "firstName",
    "lastName",
    "emailAddress",
    "contactNumber",
    "companyName",
    "state",
    "hasRead",
  ];

  const recaptchaRef: any = useRef();
  const recaptchaCheckbox: any = useRef();

  const recaptchaOnChange = async () => {
    const token = await recaptchaRef.current?.executeAsync();

    if (token) {
      setIsNotRobot(true);
    }
  };

  const handleFinalFormSubmit = (values: Values, form: FormApi<Values, Partial<Values>>) => {
    const { machinery, ...otherValues } = values;
    const submitValues: FormValues = {
      machineryType: `${machinery.brand.name} / ${machinery.category}`,
      machineryTitle: machinery.fullName,
      firstName: otherValues.firstName,
      lastName: otherValues.lastName,
      emailAddress: otherValues.emailAddress,
      contactNumber: otherValues.contactNumber,
      companyName: otherValues.companyName,
      state: otherValues.state,
      message: otherValues.message,
      hasRead: otherValues.hasRead,
    };

    setSubmitting(true);
    agent.Enquiries.sendEnquiry(submitValues)
      .then(() => {
        setSubmitting(false);
        dialogBox.info(
          "Submit Complete",
          "Your message has been sent successfully. We will contact you shortly.",
          () => {
            setTimeout(form.reset);
            for (let i = 0; i < fieldList.length; i++) {
              const field = fieldList[i];
              form.resetFieldState(field);
            }
            recaptchaRef.current.reset();
            setIsNotRobot(false);
          },
        );
      })
      .catch((error) => {
        setSubmitting(false);
        dialogBox.info("Submit Faild", JSON.stringify(error.response.data.errors), () => undefined);
        console.log(error.response);
      });
  };

  return (
    <StyledContact>
      {submitting && <LoadingComponent />}
      <div id="contact-form" className="contact__form">
        <div className="container">
          <div className="contact__form__title">Contact Us</div>
          <FinalForm
            validate={validate}
            initialValues={{ machinery }}
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid, pristine }) => (
              <Form onSubmit={handleSubmit}>
                <StyledGrid>
                  <div className="row">
                    <div className="col-1-of-2">
                      <Field name="machineryType" component="input">
                        {() => (
                          <Form.Field>
                            <input
                              className="contact__form__input--red"
                              placeholder="Machinery Type"
                              readOnly
                              value={`${machinery.brand.name.toUpperCase()} / ${machinery.category.toUpperCase()}`}
                            />
                          </Form.Field>
                        )}
                      </Field>
                    </div>
                    <div className="col-1-of-2">
                      <Field name="machineryTitle" component="input">
                        {() => (
                          <Form.Field>
                            <input
                              className="contact__form__input--red"
                              placeholder="Machinery Title"
                              readOnly
                              value={machinery.fullName.toUpperCase()}
                            />
                          </Form.Field>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="contact__form__info">
                    <span>Fields marked with * are mandatory.</span>
                  </div>
                  <div className="row">
                    <div className="col-1-of-2">
                      <Field name="firstName" placeholder="First name*" component={TextInput} />
                    </div>
                    <div className="col-1-of-2">
                      <Field name="lastName" placeholder="Last name*" component={TextInput} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1-of-2">
                      <Field
                        name="emailAddress"
                        placeholder="Email address*"
                        component={TextInput}
                      />
                    </div>
                    <div className="col-1-of-2">
                      <Field
                        name="contactNumber"
                        placeholder="Contact number*"
                        component={TextInput}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1-of-2">
                      <Field name="companyName" placeholder="Company name*" component={TextInput} />
                    </div>
                    <div className="col-1-of-2">
                      <Field name="state" placeholder="State*" component={TextInput} />
                    </div>
                  </div>
                  <Field name="message" rows={3} placeholder="Message" component={TextAreaInput} />
                </StyledGrid>
                <div className="contact__form__checkboxes">
                  <div className="checkbox">
                    <label className="checkbox__label">
                      <Field
                        validate={(value) => (!value ? "Please check" : undefined)}
                        name="hasRead"
                        component="input"
                        type="checkbox"
                      />
                      <span>
                        I have read and understand the{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://leaseplusgroup.com.au/Privacy"
                        >
                          privacy statement
                        </a>{" "}
                        and agree with its content.
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox__label">
                      <input
                        type="checkbox"
                        checked={isNotRobot}
                        disabled={isNotRobot}
                        ref={recaptchaCheckbox}
                        onChange={recaptchaOnChange}
                      />
                      <span>I am not a robot.</span>
                    </label>
                  </div>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LfQt68ZAAAAABty6M2-uSJFFrDj3FQjDjmNpBIP"
                />
                <div className="contact__form__submit">
                  <StyledButtonPrimay>
                    <Button
                      className="btn-primary"
                      disabled={submitting || invalid || pristine || !isNotRobot}
                      type="submit"
                      content="Get a quote now"
                    />
                  </StyledButtonPrimay>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </StyledContact>
  );
};
