import { createGlobalStyle } from "styled-components";
import { media } from "../base/media-queries";
import { colors } from "../abstract/variables";

import InkFree from "../../fonts/Inkfree.ttf";
import Oswald from "../../fonts/Oswald.ttf";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
        /* user-select: none; */
    }

    html {
        font-size: 62.5%; // 1rem = 10px;
        scroll-behavior: smooth;

        ${media.ipad} {
            font-size: 50%;
        }
    }

    body {
        box-sizing: border-box;
        color: ${colors.grey};
        font-size: 1.6rem;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        width: 100%;

        ${media.ipad} {
            font-size: 1.8rem;
        }

        select {
            /* for Firefox */
            -moz-appearance: none;
            /* for Chrome */
            -webkit-appearance: none;

            &:-moz-ui-invalid {
                box-shadow: none;
            }

            &::-ms-expand {
                display: none;
            }
        }
    }

    @font-face {
        font-family: "Ink Free";
        src: url(${InkFree}) format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: "Oswald";
        src: url(${Oswald}) format("truetype");
        font-weight: normal;
        font-style: normal;
    }
        
    .container {
        max-width: 114rem;
        margin: 0 auto;
        padding: 0 2rem;
    }

    a {
        &,
        &:link,
        &:visited {
            text-decoration: none;
            color: ${colors.grey};
            transition: all .2s;
        }
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    .dialog-box {
        align-items: center;
        animation: fadeIn ease-out .5s;
        background-color: rgba(0,0,0, .4);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 9999;

        &__container {
            animation: sizeIn ease-out .7s;
            background-color: ${colors.white};
            border-radius: 1rem;
            box-shadow: 0 0 1rem .5rem rgba(0,0,0, .5);
            max-width: 35rem;
            overflow: hidden;
            text-align: center;
            width: 90%;

            &__header {
                color: ${colors.white};
                font-weight: bold;
                font-size: 1.6rem;
                padding: .8rem 1rem;
            }

            &__body {
                padding: 2rem;
            }

            &__footer {
                padding: 0 1.5rem 2rem 1.5rem;

                button {
                    border: none;
                    border-radius: .5rem;
                    cursor: pointer;
                    outline: none;
                    padding: .7rem 1.5rem;
                    transition: all ease-out .3s;

                    &:not(:first-child) {
                        margin-left: 2rem;
                    }

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }

        &--alert &__container {
            &__header {
                background-color: ${colors.red};
            }

            &__footer {
                &__btn-cancel {
                    background-color: ${colors.red};
                    color: ${colors.white};
                }

                &__btn-accept {
                    display: none;
                }
            }
        }

        &--info &__container {
            &__header {
                background-color: ${colors.blue1};
            }

            &__footer {
                &__btn-cancel {
                    background-color: ${colors.blue1};
                    color: ${colors.white};
                }

                &__btn-accept {
                    display: none;
                }
            }
        }

        &--confirm &__container {
            &__header {
                background-color: ${colors.orange};
            }

            &__footer {
                &__btn-cancel {
                    color: ${colors.black};
                }

                &__btn-accept {
                    background-color: ${colors.orange};
                    color: ${colors.white};
                }
            }
        }
}

`;
