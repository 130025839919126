export const colors = {
  white: "#ffffff",
  black: "#000000",
  blue1: "#005a98",
  blue2: "#054568",
  grey: "#4d4d4d",
  grey1: "#f1f1f1",
  grey2: "#f2f2f2",
  grey3: "#2b2b2b",
  grey4: "#707070",
  red: "#AE0721",
  orange: "#f26f21",
};

export const grid = {
  width: "114rem",
  gutterVertical: "2rem",
  gutterVerticalSmall: "2rem",
  gutterHorizontal: "2rem",
};

export const bp = {
  largest: "1600px", // 100‬em
  large: "1400px", // 100em
  ipad: "1100px",
  medium: "1000px", // 62.5em
  small: "855px",
  mobile: "700px",
  smallest: "600px", // 600px
  iphonese: "370px",
};
