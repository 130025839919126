import styled from "styled-components";
import { colors } from "../../../styles/abstract/variables";
import { media } from "../../../styles/base/media-queries";

export const StyledFooter = styled.div`
  .footer {
    color: ${colors.white};
    background-color: #2e2f31;
    padding: 5rem 1rem;
    font-size: 1.4rem;

    ${media.ipad} {
      position: relative;
    }

    ${media.mobile} {
      padding-bottom: 8rem;
    }

    a {
      color: ${colors.white};

      &:hover {
        color: ${colors.white};
      }
    }

    &__items {
      display: flex;
      justify-content: space-between;
      padding-bottom: 2rem;
      border-bottom: 1px solid white;
      align-items: flex-end;

      ${media.mobile} {
        flex-wrap: wrap;
      }

      img {
        max-width: 4rem;
      }
    }

    &__item {
      ${media.mobile} {
        margin: 0 1rem 1.5rem 1rem;
      }
      a,
      b,
      p {
        display: block;
        margin-bottom: 0.5rem;
        span {
          margin-bottom: 0.5rem;
          display: block;
        }
      }
      p {
        b {
          margin-right: 1rem;
          margin-bottom: 0.5rem;
        }
      }

      &__contact {
        a,
        b {
          display: inline-block;
        }
      }
    }

    .social {
      ${media.ipad} {
        position: absolute;
        right: 10rem;
        bottom: 3rem;
      }

      ${media.medium} {
        right: 6rem;
      }

      ${media.small} {
        right: 3rem;
      }

      ${media.mobile} {
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
      }

      &__items {
        display: flex;
        align-items: flex-end;

        a {
          display: inline-block;
          margin-left: 0.5rem;
        }
      }
    }

    &__copyright {
      margin-top: 2rem;

      ${media.mobile} {
        text-align: center;
      }

      a {
        display: inline-block;
        &::before {
          content: "|";
          margin: 0 2rem;
        }
      }

      .social {
        ${media.ipad} {
          display: block;
        }
      }
    }
  }
`;
