import React from "react";
import { Link } from "react-router-dom";
import { StyledHeaderLeftbox } from "./StyledHeaderLeftbox";

interface Props {
  isHome: boolean;
}

export const HeaderLeftbox: React.FC<Props> = ({ isHome }) => {
  return (
    <StyledHeaderLeftbox>
      <div className="header__top__left-box">
        {isHome ? (
          <img src="/images/logos/ausmach-logo-mobile.png" alt="Ausmach Logo" />
        ) : (
          <Link to="/">
            <img src="/images/logos/ausmach-logo-mobile.png" alt="Ausmach Logo" />
          </Link>
        )}
      </div>
    </StyledHeaderLeftbox>
  );
};
