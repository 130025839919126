import styled from "styled-components";

import { colors } from "../../../styles/abstract/variables";
import { media } from "../../../styles/base/media-queries";

interface Props {
  isHome: boolean;
  isOpenMenu: boolean;
}

export const StyledHeader = styled.div<Props>`
  .header {
    background-image: url("/images/header/Group2329.png");
    background-position: top;
    background-repeat: no-repeat;
    padding: 2.5rem 0;
    height: ${(p) => (p.isHome ? "75rem" : "35rem")};

    ${media.ipad} {
      height: ${(p) => (p.isHome ? "86rem" : "30rem")};
    }

    ${media.mobile} {
      position: ${(p) => (p.isHome ? "fixed" : "inherit")};
      top: ${(p) => (p.isHome ? "0" : "unset")};
      width: 100%;
      z-index: -1;
      height: ${(p) => (p.isHome ? "80rem" : "10rem")};
      background-position: ${(p) => (p.isHome ? "top" : "unset")};
      background-image: url("/images/header/header-mobile.png");
      background-size: cover;
    }

    &__logo {
      display: block;

      ${media.ipad} {
        max-width: 30rem;
        display: inline-block;
      }

      ${media.mobile} {
        max-width: 25rem;
      }
    }

    &__menu {
      height: 100vh;
      position: fixed;
      overflow: auto;
      width: 100vw;
      right: -100vw;
      top: 10rem;
      z-index: 9999;
      transform-origin: right;
      transition: all 0.3s ease;
      background-color: ${colors.white};
      overflow-x: hidden;
      right: ${(p) => (p.isOpenMenu ? "0" : "-100vw")};

      &__close {
        border: none;
        background-color: transparent;
        cursor: pointer;
        height: 3.5rem;
        outline: none;
        position: absolute;
        right: 2rem;
        top: 3rem;
        width: 3.5rem;

        &::before,
        &::after {
          background-color: #fff;
          content: "";
          display: block;
          height: 2px;
          position: absolute;
          top: 50%;
          width: 100%;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }

      &__container {
        padding: 1rem 2rem;
        padding-bottom: 22rem;
      }

      &__list {
        &--item {
          display: block;
          background-color: ${colors.grey2};
          border-radius: 10px;
          margin-bottom: 1rem;
          font-size: 2rem;
          font-weight: 600;
          padding: 2rem;

          .fa-angle-down {
            right: 4rem;
            font-size: 2.5rem;
            color: ${colors.red};
            position: absolute;
            transition: all 0.2s;
          }

          &.active {
            .fa-angle-down {
              transform: rotate(180deg);
            }
          }
        }

        .line {
          margin: 5px 0;
          height: 1px;
          background: repeating-linear-gradient(
            to right,
            ${colors.grey4} 0,
            ${colors.grey4} 5px,
            transparent 5px,
            transparent 7px
          );
        }
      }

      &__products {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;

        &.active {
          max-height: 0;
        }

        &--item {
          &--ALURangerOneR,
          &--ALURangerVGroove {
            height: 23rem;
          }

          &--OtherACMMachinery,
          &--Edgebanders {
            height: 22rem;
          }

          &--DustExtractors,
          &--ALUBender {
            height: 28rem;
          }

          &,
          &:link,
          &:visited {
            text-decoration: none;
            color: ${colors.grey};
            border-bottom: 1px dashed ${colors.grey4};
          }

          &:nth-child(2n + 1) {
            border-right: 1px dashed ${colors.grey4};
          }

          width: 49%;
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          padding: 1rem 3rem;

          &__title {
            font-size: 2.2rem;
            font-weight: 500;
            color: ${colors.red};
            text-align: center;
            margin-bottom: auto;
          }

          &__image {
            width: 100%;
            padding: 1rem 0;
            margin: auto 0;
            max-width: 20rem;

            &--ALURangerOneR,
            &--ALURangerVGroove {
              height: 13rem;
            }

            &--Edgebanders {
              margin-top: 3rem;
              width: auto;
            }

            &--DustExtractors,
            &--ALUBender {
              height: auto;
              max-height: 17rem;
              width: auto;
            }
          }

          &__footer {
            font-size: 1.8rem;
            font-weight: 400;
          }
        }
      }
    }

    &__top {
      align-items: center;
      display: flex;
      margin: 0 auto;
      max-width: 136.6rem;
      padding: 0 2.5rem;
      font-weight: 800;

      ${media.mobile} {
        justify-content: space-between;
        display: ${(p) => (p.isHome ? "none" : "flex")};
      }

      &__left-box {
        img {
          max-width: 22rem;
        }
      }

      &__right-box {
        color: ${colors.white};
        font-size: 3.5rem;
        font-weight: 100;
      }
    }

    &__content {
      max-width: 99rem;
      margin: 0 auto;
      padding: 0 2rem;
      padding-top: 5rem;
      color: ${colors.white};

      ${media.ipad} {
        max-width: 80rem;
      }

      ${media.mobile} {
        max-width: 60rem;
        width: 80%;
        display: none;
      }

      &__sub {
        font-size: 2.4rem;
        font-weight: 300;

        &--top {
          text-align: start;
        }

        &--bottom {
          text-align: right;

          ${media.mobile} {
            text-align: start;
          }
        }
      }

      &__main {
        text-align: center;
        font-size: 5.2rem;
        margin: 3rem 0;
        font-weight: 500;

        ${media.ipad} {
          font-size: 4.2rem;
          margin: 2rem 0;
        }

        ${media.mobile} {
          text-align: start;
          line-height: 1.2;
        }
      }
    }
  }
`;
