import styled from "styled-components";
import { media } from "../../../styles/base/media-queries";
import { colors } from "../../../styles/abstract/variables";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const StyledMachineryPageDetails = styled.div`
  .details {
    &__container {
      padding: 0 2rem 4rem 2rem;

      ${media.mobile} {
        padding: 0 0 4rem 0;
      }
    }
    &__slick {
      &__nav {
        padding: 2rem;
        text-align: center;

        ${media.mobile} {
          padding: 2rem 0;
          font-weight: 500;
        }

        .slick-current {
          .details__slick__nav__item {
            text-decoration: underline;
          }
        }

        .slick-slide {
          display: inline-block !important;
          padding: 0 !important;
          margin: auto !important;
          width: auto !important;
          float: none;

          div:first-of-type {
            display: inline-block;
            width: auto;
          }

          &:after {
            display: inline-block;
            content: "*";
          }

          &:last-of-type:after {
            display: none;
          }
        }
        &__item {
          font-size: 1.8rem;
          cursor: pointer;
          border: transparent;
          text-transform: uppercase;
          padding: 1rem 4rem;

          &:focus {
            outline: none;
          }
        }
      }

      &__content {
        padding: 1rem;

        .slick-list {
          transition: all 0.2s;
        }
        &__item {
          &:focus {
            outline: none;
          }
        }
      }
    }

    &__features {
      &__title {
        text-align: center;
        text-transform: uppercase;
        color: ${colors.red};
        font-size: 3.2rem;
        font-weight: 300;
        margin: 4rem;
      }
      ul {
        padding-left: 4rem;
        list-style: none;

        li {
          margin-bottom: 1rem;
          position: relative;

          &::before {
            content: "*";
            position: absolute;
            left: -1.5rem;
            top: 3px;
          }
        }
      }

      &__items {
        max-width: 99rem;
        margin: 0 auto;
      }

      &__item {
        &.col-1-of-2 {
          ${media.mobile} {
            margin-bottom: 0;
          }
        }

        &__title {
          font-size: 1.8rem;
          font-weight: 500;
          color: #6d6e70;
          font-style: italic;
          margin-bottom: 2rem;
        }
      }
    }

    &__addInfo {
      &__title {
        font-size: 2rem;
        font-weight: 300;
        font-style: italic;
        color: #be1e2d;
      }

      &__subtitle {
        font-size: 2rem;
        font-weight: 300;
        font-style: italic;
        margin: 1rem 0;
      }

      &__descriptions {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }

    &__videos {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      iframe {
        height: 50rem;
        width: 100rem;

        ${media.ipad} {
          width: 80%;
        }

        ${media.mobile} {
          width: 90%;
        }
      }

      &__additional {
        width: 80%;
        max-width: 50rem;

        &__links {
          .buttons {
            margin-bottom: 4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            ${media.mobile} {
              flex-direction: column;
            }
          }

          .ui.button {
            background-color: ${colors.red};
            color: ${colors.white};
            display: block;

            ${media.mobile} {
              &:not(:last-child) {
                margin-bottom: 1rem;
              }
            }

            &:hover,
            &.active {
              color: ${colors.white};
              background-color: #630312;
            }
          }
        }
      }
    }

    table {
      background-color: #fafafa;
      margin: 0 auto;
      border-collapse: collapse;

      th {
        color: black;
        font-weight: 500;
        text-align: start;
      }

      tr {
        padding: 1rem;

        td {
          padding: 0.5rem;
          min-width: auto;
          min-width: unset;
          max-width: auto;
          max-width: unset;
          width: 50rem;
          font-size: 1.5rem;

          @media only screen and (max-width: 1000px) {
            min-width: auto;
            min-width: unset;
          }

          hr {
            margin: 5px 0 7px 0;
          }
        }

        th {
          padding: 1rem;
          font-size: 1.5em;
        }
      }

      tr:nth-child(even) {
        background-color: ${colors.grey2};

        td {
          color: black;

          hr {
            border-color: black;
          }
        }
      }

      tr:first-child {
        background-color: white;
      }

      tr:last-child,
      tr:first-child {
        border-bottom: 3px solid #a6a6a6;
        border-top: none;
      }

      td {
        border-bottom: 1px solid #86888c;
        border-top: none;
      }

      hr {
        border-top: 1px solid black;
      }
    }

    table td,
    table th {
      padding: 0.5rem;
    }

    table tr th {
      padding: 0.5rem;
      font-size: 1em;
    }

    .table-cnc {
      tr td {
        min-width: 32rem;

        @media only screen and (max-width: 1000px) {
          min-width: 20rem;
        }

        @media only screen and (max-width: 700px) {
          min-width: auto;
          min-width: unset;
        }
      }
    }
  }
`;
