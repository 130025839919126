import React from "react";
import { StyledDisclaimer } from "./StyledDisclaimerAndLegal";
import { StyledBreadcrumb } from "../../../styles/components/StyledBreadcrumb";
import { Link } from "react-router-dom";

export const DisclaimerAndLegal = () => {
  return (
    <StyledDisclaimer>
      <div className="disclaimer">
        <StyledBreadcrumb>
          <div className="breadcrumb">
            <div className="container">
              <Link to={"/"} className="breadcrumb__item">
                Home
              </Link>
              <div className="breadcrumb__item active">Disclaimer and Legal</div>
            </div>
          </div>
        </StyledBreadcrumb>
        <div className="container">
          <div className="disclaimer__title">Disclaimer and Legal</div>
          <div className="disclaimer__subtitle">Website Disclaimer</div>
          <div className="disclaimer__content">
            <p>
              The information and materials on this Website (including but not limited to the
              software, calculators, tools, logos and names) is derived from sources believed to be
              accurate and current as of the date of posting to the Website.
            </p>
            <p>
              LeasePLUS, and its officers, agents, employees and contractors do not (1) give any
              representation or warranty as to the reliability, accuracy or completeness of the
              information and materials on this Website; nor (2) warrant that your access to the
              Website will be uninterrupted or error-free.
            </p>
            <p>
              LeasePLUS nor its employees shall not be liable in any way for any loss or damage,
              however arising, whether in negligence or otherwise, out of or in connection with the
              information or omissions on the Website (including any linked site).
            </p>
          </div>
          <div className="disclaimer__subtitle">Indemnity</div>
          <div className="disclaimer__content">
            <p>
              You agree to indemnify and hold harmless LeasePLUS and its employees and keep them
              indemnified, against all loss, actions, proceedings, costs, expenses (including legal
              fees), claims and damages arising from: (1) any breach by you of the Terms and
              Conditions of Use; or (2) reliance by you on any information obtained through this
              Website; or (3) your access and or use of the Site or any information downloaded from
              it; or (4) reliance upon, or use of, any information by any person accessing it
              through your Website.
            </p>
          </div>
        </div>
      </div>
    </StyledDisclaimer>
  );
};
