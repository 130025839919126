import React, { useState } from "react";
import styled from "styled-components";
import { Header } from "./Header/Header";
import { Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";
import { HomePage } from "../pages/HomePage/HomePage";
import { SubCategoryPage } from "../pages/SubCategoryPage/SubCategoryPage";
import { MachineryPage } from "../pages/MachineryPage/MachineryPage";
import NotFound from "./NotFound/NotFound";
import { Footer } from "./Footer/Footer";
import ScrollToTop from "../common/ScrollToTop";
import { DisclaimerAndLegal } from "../pages/DisclaimerAndLegal/DisclaimerAndLegal";

interface Props {
  isOpenMenu?: boolean;
}

const StyledApp = styled.div<Props>`
  &.active {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
  }
`;

const App = ({ history }: RouteComponentProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <StyledApp isOpenMenu={isOpenMenu} className={isOpenMenu ? "active" : ""}>
      <ScrollToTop />
      {history.location.pathname !== "/" ? (
        <Header
          isHome={false}
          history={history}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={setIsOpenMenu}
        />
      ) : (
        <Header
          isHome={true}
          history={history}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={setIsOpenMenu}
        />
      )}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/DisclaimerAndLegal" component={DisclaimerAndLegal} />
        <Route exact path="/machinery/:subcategoryName" component={SubCategoryPage} />
        <Route exact path="/machinery/:subcategoryName/:machineryName" component={MachineryPage} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </StyledApp>
  );
};

export default withRouter(App);
