import styled from "styled-components";
import { media } from "./media-queries";

export const DisplayIpad = styled.div`
  .u-display-ipad {
    display: none;

    ${media.ipad} {
      display: block;
    }
  }
`;

export const DisplayMobile = styled.div`
  .u-display-mobile {
    display: none;

    ${media.mobile} {
      display: block;
    }
  }
`;
