const customMediaQuery = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

export const media = {
  custom: customMediaQuery,
  largest: customMediaQuery(1600),
  large: customMediaQuery(1400),
  ipad: customMediaQuery(1100),
  medium: customMediaQuery(1000),
  small: customMediaQuery(855),
  mobile: customMediaQuery(700),
  smallest: customMediaQuery(600),
};
