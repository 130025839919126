import React from "react";
import { Segment, Button, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { StyledButtonPrimay } from "../../../styles/components/StyledButton";

const NotFound = () => {
  return (
    <Segment style={{ minHeight: 500, backgroundColor: "white", fontSize: 15 }} placeholder>
      <Header icon>
        <Icon name="search" />
        Oops - we&apos;ve looked everywhere but couldn&apos;t find this.
      </Header>
      <Segment.Inline>
        <StyledButtonPrimay>
          <Button className="btn-primary" as={Link} to="/">
            Home page
          </Button>
        </StyledButtonPrimay>
      </Segment.Inline>
    </Segment>
  );
};

export default NotFound;
