import React from "react";
import { StyledLoadingComponent } from "./StyledLoadingComponent";
import { Loader } from "semantic-ui-react";

export const LoadingComponent = () => {
  return (
    <StyledLoadingComponent>
      <div className="loading-panel">
        <div className="loading-panel__container">
          <div className="loading-panel__spinner-box">
            <Loader active inline="centered" size="large" />
          </div>
          <div className="loading-panel__text-box">Loading</div>
        </div>
      </div>
    </StyledLoadingComponent>
  );
};
