import styled from "styled-components";
import { colors } from "../../../styles/abstract/variables";
import { media } from "../../../styles/base/media-queries";

export const StyledMachineryPage = styled.div`
  .machinery-page {
    .breadcrumb {
      ${media.mobile} {
        display: none;
      }
    }

    .u-display-mobile {
      .back,
      .back:link,
      .back:visited {
        display: block;
        margin-top: 2rem;
        margin-left: 4rem;
        font-size: 1.8rem;
        text-decoration: none;
        color: ${colors.grey};
      }

      .fa-angle-left {
        margin-right: 1rem;
        font-weight: 800;
      }

      .title {
        margin-left: 4rem;
        color: ${colors.red};
        max-width: 13ch;
        overflow: hidden;
        font-size: 3.6rem;
        margin-top: 3rem;
        line-height: 1.2;
      }
    }

    .brand {
      padding: 4rem;

      ${media.mobile} {
        padding: 4rem 2rem;
      }

      &__logo {
        margin-bottom: 1.5rem;

        img {
          height: 4rem;
        }
      }

      &__title {
        margin-bottom: 1.5rem;
      }
    }

    .description {
      background-color: ${colors.grey2};
      padding: 4rem 2rem;

      &__quote {
        font-size: 2.4rem;
      }

      &__details {
        display: flex;
        align-items: center;

        ${media.ipad} {
          flex-direction: column;
        }

        &__ipad {
          display: none;

          ${media.ipad} {
            display: block;
            margin-bottom: 1rem;
          }

          .subtitle,
          .title {
            font-size: 3.5rem;
            font-weight: 500;

            ${media.mobile} {
              display: block;
              margin-bottom: 2rem;
              text-align: center;
            }
          }

          .subtitle {
            margin-right: 2rem;
            color: ${colors.black};

            ${media.mobile} {
              margin-right: 0;
            }
          }

          .title {
            color: ${colors.red};
          }
        }
      }

      &__img {
        display: inline-block;
        width: 50%;
        margin-right: 4rem;

        ${media.ipad} {
          width: 100%;
          margin: 0 auto;
          margin-bottom: 2rem;
        }

        &__img {
          width: 100%;
          object-fit: contain;
          margin: 0 auto;
          display: block;

          ${media.mobile} {
            width: 100%;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: flex-start;

        ${media.ipad} {
          width: auto;
        }

        &__subtitle {
          font-weight: 500;
          font-size: 2.4rem;

          ${media.ipad} {
            display: none;
          }
        }

        &__title {
          font-size: 3.6rem;
          padding-top: 1.5rem;
          padding-bottom: 4rem;
          color: ${colors.red};

          ${media.ipad} {
            display: none;
          }
        }

        &__details {
          width: 100%;
          padding-bottom: 4rem;
        }

        &__quote {
          ${media.ipad} {
            align-self: center;
          }
        }
      }
    }
  }
`;
