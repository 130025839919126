import styled from "styled-components";
import { colors } from "../../../styles/abstract/variables";
import { clearBothMixin } from "../../../styles/abstract/mixins";
import { media } from "../../../styles/base/media-queries";

export const StyledHomePage = styled.div`
  .home-page {
    .header {
      &__logo {
        display: block;

        ${media.mobile} {
          max-width: 25rem;
        }
      }

      &__top {
        align-items: center;
        display: flex;
        margin: 0 auto;
        max-width: 136.6rem;
        padding: 2.5rem 2.5rem 0 2.5rem;
        font-weight: 800;

        ${media.mobile} {
          justify-content: center;
        }
      }
      &__content {
        max-width: 99rem;
        margin: 0 auto;
        padding: 0 2rem;
        padding-top: 5rem;
        color: ${colors.white};

        ${media.mobile} {
          max-width: 60rem;
          padding-top: 3rem;
          width: 80%;
        }

        &__sub {
          font-size: 2.4rem;
          font-weight: 300;

          &--top {
            text-align: start;
          }

          &--bottom {
            text-align: end;

            ${media.mobile} {
              text-align: start;
            }
          }
        }

        &__main {
          margin: 2rem 0;
          font-weight: 500;

          ${media.mobile} {
            text-align: start;
            line-height: 1.2;
            font-size: 4.2rem;
          }
        }
      }
    }
    .cards {
      max-width: 110rem;
      margin: -49.5rem auto 6rem auto;
      padding: 0 2rem;

      ${clearBothMixin}

      ${media.ipad} {
        margin-top: -57.5rem;
        max-width: 90rem;
        padding-right: 0;
      }

      ${media.mobile} {
        margin-top: 4rem;
        padding-left: 0;
      }

      ${media.mobile} {
        max-width: 80rem;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .card {
      min-height: 45rem;

      ${media.ipad} {
        min-height: 52.5rem;
      }

      ${media.mobile} {
        min-height: 45rem;
      }

      &__link__image {
        margin-top: auto;
      }

      &__image {
        ${media.ipad} {
          height: 25rem;
        }

        ${media.mobile} {
          height: 20rem;
        }

        &--OtherACMMachinery {
          width: 100%;
          max-width: 30rem;

          ${media.ipad} {
            height: 20rem;
            margin: 4.5rem 0;
          }
        }

        &--Edgebanders {
          height: 15rem;
          margin: 2.5rem 0;

          ${media.ipad} {
            height: 19rem;
            margin: 5rem 0;
          }

          ${media.mobile} {
            margin: 0;
          }
        }

        &--DustExtractors {
          min-height: auto;
          min-height: unset;
          height: 20rem;

          ${media.ipad} {
            height: 30rem;
          }
        }

        &--MobilDustExtractors {
          min-height: auto;
          min-height: unset;
          height: 20.5rem;
          width: 70%;
          margin: 0 auto;
          display: block;

          ${media.ipad} {
            height: 30.5rem;
          }
        }
      }

      &__types {
        margin-bottom: 2rem;
        margin-top: auto;
      }

      &__type {
        font-size: 1.4rem;
        transition: all 0.2s;
        display: inline-block;
        margin-top: 0.5rem;

        &,
        &:link,
        &:visited {
          text-decoration: none;
          color: ${colors.grey3};

          &:hover {
            transform: translateY(-3px);
          }
        }

        &:not(:last-child) {
          &::after {
            content: "/";
            margin: 0 0.5rem;
          }
        }
      }
    }
  }
`;
