import styled from "styled-components";
import { colors } from "../../../styles/abstract/variables";

export const StyledLoadingComponent = styled.div`
  .loading-panel {
    animation: fadeIn ease 0.5s;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;

    &__container {
      animation: sizeIn ease-out 0.7s;
      background-color: ${colors.white};
      border-radius: 2rem;
      box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.5);
      min-width: 30rem;
      padding: 2rem;
      text-align: center;
      width: 20%;
    }

    &__spinner-box {
      margin-bottom: 2rem;
    }

    &__text-box {
      font-size: 3rem;
    }
  }
`;
