/* eslint-disable @typescript-eslint/no-use-before-define */
// type: string;
// title: string;
// response: any;
// callback: () => void;
// cancelText: string;
// acceptText: string;

export const dialogBox = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  alert: (title: string, response: any, callback: () => void) => {
    let message = response;

    if (response.data) {
      if (response.data.message) message = response.data.message;
      else message = response.data;
    }

    dialogBoxFunction("alert", title, message, callback, "OK", null);
  },
  info: (title: string, message: string, callback: () => void) => {
    dialogBoxFunction("info", title, message, callback, "OK", null);
  },
  confirm: (title: string, message: string, callback: () => void) => {
    dialogBoxFunction("confirm", title, message, callback, "No", "Yes");
  },
};

function dialogBoxFunction(
  type: string,
  title: string,
  message: string,
  callback: (bool?: boolean) => void,
  cancelText: string | null,
  acceptText: string | null,
) {
  const rootBody = document.getElementsByTagName("body")[0];
  const wrapper = document.createElement("div");
  const container = document.createElement("div");
  const header = document.createElement("div");
  const body = document.createElement("div");
  const footer = document.createElement("div");
  const cancelBtn = document.createElement("button");
  const acceptBtn = document.createElement("button");

  header.innerHTML = title;
  body.innerHTML = message;
  cancelBtn.innerHTML = cancelText ? cancelText : "";
  acceptBtn.innerHTML = acceptText ? acceptText : "";

  wrapper.classList.add("dialog-box");
  wrapper.classList.add(`dialog-box--${type}`);
  container.classList.add("dialog-box__container");
  header.classList.add("dialog-box__container__header");
  body.classList.add("dialog-box__container__body");
  footer.classList.add("dialog-box__container__footer");
  cancelBtn.classList.add("dialog-box__container__footer__btn-cancel");
  acceptBtn.classList.add("dialog-box__container__footer__btn-accept");

  rootBody.appendChild(wrapper);
  wrapper.appendChild(container);
  container.appendChild(header);
  container.appendChild(body);
  container.appendChild(footer);

  if (acceptText) footer.appendChild(acceptBtn);

  if (cancelText) footer.appendChild(cancelBtn);

  acceptBtn.addEventListener("click", function () {
    if (callback) callback(true);

    rootBody.removeChild(wrapper);
  });

  cancelBtn.addEventListener("click", function () {
    if (callback) callback(false);

    rootBody.removeChild(wrapper);
  });
}
