/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import Machinery from "../../../models/Machinery";
import agent from "../../../api/agent";
import { HomeCardsPlaceholder } from "../../common/HomeCardsPlaceholder/HomeCardsPlaceholder";
import { RouteComponentProps, Link } from "react-router-dom";
import { StyledBreadcrumb } from "../../../styles/components/StyledBreadcrumb";
import { StyledSubCategoryPage } from "./StyledSubCategoryPage";
import { StyledCard } from "../../../styles/components/StyledCard";
import { DisplayMobile } from "../../../styles/base/utilities";
import Slider from "react-slick";
import { Icon } from "semantic-ui-react";

interface Params {
  subcategoryName: string;
}

export const SubCategoryPage: React.FC<RouteComponentProps<Params>> = ({ match, history }) => {
  const [machineries, setMachineries] = useState<Machinery[]>([]);
  const [loading, setLoading] = useState(true);
  const [slickIndex, setSlickIndex] = useState<number>(1);
  const [slider, setSlider] = useState<any>();

  const onSlickPrevClick = () => {
    slider?.slickPrev();
  };

  const onSlickNextClick = () => {
    slider?.slickNext();
  };

  let subcategoryId: string;
  let subcategoryDescription: string[] = [];
  const unslick: "unslick" = "unslick";
  const settingsSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    beforeChange: (current: number, next: number) => setSlickIndex(next + 1),
    responsive: [
      {
        breakpoint: 700,
        settings: {},
      },
      {
        breakpoint: 10000,
        settings: unslick,
      },
    ],
  };

  switch (match.params.subcategoryName) {
    case "ALURangerOneR":
      subcategoryId = "1";
      subcategoryDescription = [
        "Our ACM Machinery offers a range of CNC machining centres and milling bending machines that are conceived to obtain optimum performances and productivity processing.",
        "The range consists of Alu Ranger, an Alu Loader and an Alu Bender.",
      ];
      break;
    case "ALURangerVGroove":
      subcategoryId = "2";
      subcategoryDescription = [
        "Our ACM Machinery offers a range of CNC machining centres and milling bending machines that are conceived to obtain optimum performances and productivity processing.",
        "The range consists of Alu Ranger, an Alu Loader and an Alu Bender.",
      ];
      break;
    case "OtherACMMachinery":
      subcategoryId = "3";
      subcategoryDescription = [
        "Our ACM Machinery offers a range of CNC machining centres and milling bending machines that are conceived to obtain optimum performances and productivity processing.",
        "The range consists of Alu Ranger, an Alu Loader and an Alu Bender.",
      ];
      break;
    case "Edgebanders":
      subcategoryId = "4";
      subcategoryDescription = [
        "Our edgebanders have been selected with speed, versatility, and reliability in mind.",
        "Whether you handle big production runs or need a compact solution, we have a machine to suit your needs.",
      ];
      break;
    case "DustExtractors":
      subcategoryId = "5";
      subcategoryDescription = [
        "We have carefully selected a range of dust extraction and collection machines designed to suit both and big small manufacturers across the woodworking and metal industries.",
      ];
      break;
    case "ALUBender":
      subcategoryId = "6";
      subcategoryDescription = [
        "Our ACM Machinery offers a range of CNC machining centres and milling bending machines that are conceived to obtain optimum performances and productivity processing.",
        "The range consists of Alu Ranger, an Alu Loader and an Alu Bender.",
      ];
      break;
    default:
      subcategoryId = "100";
      break;
  }

  useEffect(() => {
    setLoading(true);
    agent.Machineries.listBySubCategory(subcategoryId)
      .then((response) => {
        setMachineries(response);
      })
      .then(() => setLoading(false))
      .catch(() => history.push("/notfound"));
  }, [subcategoryId, history]);

  return (
    <StyledSubCategoryPage>
      <div className="subcategory-page">
        {loading || !machineries ? (
          <div className="container">
            <HomeCardsPlaceholder />
          </div>
        ) : (
          <div>
            <DisplayMobile>
              <div className="u-display-mobile">
                <div className="title">
                  <div className="title__content">{machineries[0].subCategory} series</div>
                </div>
              </div>
            </DisplayMobile>
            <StyledBreadcrumb>
              <div className="breadcrumb">
                <div className="container">
                  <Link to={"/"} className="breadcrumb__item">
                    Home
                  </Link>
                  <div className="breadcrumb__item active">{machineries[0].subCategory}</div>
                </div>
              </div>
            </StyledBreadcrumb>
            <DisplayMobile>
              <div className="u-display-mobile">
                {subcategoryDescription && (
                  <div className="description">
                    <div className="container">
                      {subcategoryDescription.map((d, i) => (
                        <p key={i}>{d}</p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </DisplayMobile>
            <div className="container subcategory-page__container">
              <div className="subcategory-page__title">{machineries[0].subCategory}</div>
              <div className="cards">
                <DisplayMobile>
                  <div className="u-display-mobile">
                    {machineries.length > 1 && (
                      <div className="cards__count">
                        <Icon link onClick={onSlickPrevClick} size="large" name="angle left" />
                        <span>
                          {slickIndex} / {machineries.length}
                        </span>
                        <Icon link onClick={onSlickNextClick} size="large" name="angle right" />
                      </div>
                    )}
                  </div>
                </DisplayMobile>
                <StyledCard>
                  <Slider
                    ref={(slider: any) => setSlider(slider)}
                    className="cards__slick"
                    {...settingsSlider}
                  >
                    {machineries.map((machinery) => (
                      <Link
                        to={`/machinery/${machinery.subCategory.replace(/\s/g, "")}/${
                          machinery.name
                        }`}
                        key={machinery.name}
                        className="card"
                      >
                        <div className="card__header">
                          {machinery.brand.name.toUpperCase()} / {machinery.category.toUpperCase()}
                        </div>
                        <div className="card__title">
                          {machinery.fullName.replace("VGroove", "VG")}
                        </div>
                        <img
                          src={machinery.imageUrl}
                          alt={machinery.title}
                          className={`card__image card__image--${machinery.subCategory.replace(
                            /\s/g,
                            "",
                          )}`}
                        />
                        <img
                          src={machinery.brand.imageUrl}
                          alt={machinery.brand.name}
                          className="card__logo"
                        />
                      </Link>
                    ))}
                  </Slider>
                </StyledCard>
              </div>
            </div>
          </div>
        )}
      </div>
    </StyledSubCategoryPage>
  );
};
