/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Machinery from "../../../models/Machinery";
import Slider from "react-slick";
import { StyledGrid } from "../../../styles/components/StyledGrid";
import { StyledMachineryPageDetails } from "./StyledMachineryPageDetails";
import { Button } from "semantic-ui-react";

interface Props {
  machinery: Machinery;
}

export const MachineryPageDetails: React.FC<Props> = ({ machinery }) => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [videoCurrentUrl, setVideoCurrentUrl] = useState<string>();
  const [activeButtonNumber, setActiveButtonNumber] = useState<number>();

  const additionalVideosUrls = machinery.additionalVideos;

  const onAdditionalVideoLinkClick = (index: number) => {
    setVideoCurrentUrl(additionalVideosUrls[index]);
    setActiveButtonNumber(index);
  };

  const settingsSliderNav = {
    slidesToShow: machinery.additionalInfo.title ? 4 : 3,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
  };

  const settingsSliderContent = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <StyledMachineryPageDetails>
      <section className="details">
        <div className="container">
          <Slider
            {...settingsSliderNav}
            asNavFor={nav2}
            ref={(slider1: any) => setNav1(slider1)}
            className="details__slick__nav"
          >
            <div className="details__slick__nav__item">Key features</div>
            <div className="details__slick__nav__item">Specifications</div>
            {machinery.additionalInfo.title && (
              <div className="details__slick__nav__item">Additional Info</div>
            )}
            <div className="details__slick__nav__item">Video</div>
          </Slider>
        </div>
        <div className="details__container">
          <Slider
            asNavFor={nav1}
            ref={(slider2: any) => setNav2(slider2)}
            className="details__slick__content"
            {...settingsSliderContent}
          >
            <div className="details__features details__slick__content__item">
              <div className="details__features__title">Key Features</div>
              {machinery.featuresOne.length > 1 || machinery.featuresTwo.length > 1 ? (
                <StyledGrid>
                  <div className="row">
                    <div className="details__features__items">
                      <div className="details__features__item col-1-of-2">
                        {machinery.featuresTitles[0] && (
                          <div className="details__features__item__title">
                            {machinery.featuresTitles[0]}
                          </div>
                        )}
                        <ul>
                          {machinery.featuresOne.map((f, i) => (
                            <li key={i}>{f}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="details__features__item col-1-of-2">
                        {machinery.featuresTitles[0] && (
                          <div className="details__features__item__title">
                            {machinery.featuresTitles[1]}
                          </div>
                        )}
                        <ul>
                          {machinery.featuresTwo.map((f, i) => (
                            <li key={i}>{f}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </StyledGrid>
              ) : (
                <div style={{ textAlign: "center" }}>Coming soon!</div>
              )}
            </div>
            <div className="details__tables details__slick__content__item">
              <div className="details__features__title">Specifications</div>
              <div className="container">
                <div dangerouslySetInnerHTML={{ __html: machinery.specificationsTable }}></div>
              </div>
            </div>
            {machinery.additionalInfo.title && (
              <div className="details__addInfo details__slick__content__item">
                <div className="container">
                  <div className="details__features__title">Additional Info</div>

                  <div className="details__addInfo__title">{machinery.additionalInfo.title}</div>
                  <div className="details__addInfo__subtitle">
                    {machinery.additionalInfo.subTitle}
                  </div>
                  <div className="details__addInfo__descriptions">
                    {machinery.additionalInfo.descriptions.map((d, i) => (
                      <p key={i}>{d}</p>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="details__slick__content__item">
              <div className="details__features__title">Video</div>
              <div className="details__videos">
                {machinery.additionalVideos.length > 0 && (
                  <div className="details__videos__additional">
                    <div className="details__videos__additional__links">
                      <Button.Group size="large">
                        {machinery.additionalVideosTitles.map((t, index) => (
                          <Button
                            content={t}
                            icon="play"
                            labelPosition="right"
                            onClick={() => onAdditionalVideoLinkClick(index)}
                            key={index}
                            active={index === activeButtonNumber}
                            className="link"
                          ></Button>
                        ))}
                      </Button.Group>
                    </div>
                  </div>
                )}
                {machinery.videoUrl ? (
                  <iframe
                    title={machinery.fullName}
                    src={videoCurrentUrl ? videoCurrentUrl : machinery.videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <div style={{ textAlign: "center" }}>Coming soon!</div>
                )}
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </StyledMachineryPageDetails>
  );
};
