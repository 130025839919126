import styled from "styled-components";
import { colors } from "../../../styles/abstract/variables";
import { clearBothMixin } from "../../../styles/abstract/mixins";
import { media } from "../../../styles/base/media-queries";

export const StyledSubCategoryPage = styled.div`
  .subcategory-page {
    background: ${colors.grey1};

    &__container {
      padding: 6rem 2rem;

      ${media.ipad} {
        max-width: 95rem;
        padding-right: 0;
      }

      ${media.mobile} {
        padding-right: 2rem;
        padding-top: 6rem;
      }
    }

    &__title {
      text-align: center;
      padding-bottom: 6rem;
      font-size: 3.2rem;
      color: ${colors.red};

      ${media.mobile} {
        display: none;
      }
    }

    .cards {
      text-align: center;

      ${clearBothMixin}

      ${media.mobile} {
        max-width: 45rem;
        margin: 0 auto;
      }

      &__count {
        margin-bottom: 2rem;
        color: ${colors.red};
        font-size: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin: 0 1rem;
        }
      }

      &__slick {
        .slick {
          &-prev,
          &-next {
            display: none;
            top: -31px;
            height: 4rem;

            &:before {
              display: none;
              font-size: 4rem;
              color: ${colors.red};
              font-weight: 800;
            }
          }
          &-next {
            right: 30%;
            &:before {
              content: "›";
            }
          }

          &-prev {
            left: 30%;
            &:before {
              content: "‹";
            }
          }
        }
      }
    }

    .card {
      ${media.mobile} {
        max-width: auto;
        max-width: unset;
        width: 100%;
        transform: scale(0.95);
      }
      &__image {
        width: 100%;

        ${media.ipad} {
          width: auto;
          margin: 0 auto;
        }

        &--Edgebanders {
          height: 17rem;
          margin: 0 auto;
        }

        &--ALURangerVGroove {
          width: 100%;
        }

        &--DustExtractors {
          width: auto;
        }
      }

      &__logo {
        margin-top: 2rem;
      }
    }

    .u-display-mobile {
      .title {
        padding: 2rem 4rem;
        background-position: center;
        background-image: url(/images/header/no-red-small.png);
        background-size: cover;

        &__content {
          font-size: 3.6rem;
          color: ${colors.white};
          font-weight: 400;
          line-height: 1.4;
          width: 65%;
        }
      }

      .description {
        background-color: ${colors.white};
        padding: 4rem 2rem;

        p {
          font-size: 2.2rem;
        }
      }
    }
  }
`;
