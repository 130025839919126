import React from "react";

export const HeaderContent = () => {
  return (
    <div className="header__content">
      <div className="header__content__sub header__content__sub--top">The leading supplier of</div>
      <div className="header__content__main">Woodworking &amp; Composite Machinery</div>
    </div>
  );
};
