import axios, { AxiosResponse } from "axios";
import MachineryBySubCategory from "../models/MachineryBySubCategory";
import Machinery from "../models/Machinery";
import FormValues from "../models/FormValues";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = (response: AxiosResponse) => response.data;

// const sleep = (ms: number) => (response: AxiosResponse) =>
//   new Promise<AxiosResponse>((resolve) => setTimeout(() => resolve(response), ms));

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
};

const Machineries = {
  listAll: () => requests.get("/machinery"),
  listBySubCategory: (subcategoryId: string): Promise<Machinery[]> =>
    requests.get(`/machinery?subcategoryId=${subcategoryId}`),
  machineryBySubCategory: (): Promise<MachineryBySubCategory[]> =>
    requests.get("/machinery/machineryBySubCategory"),
  machinery: (name: string) => requests.get(`machinery/${name}`),
};

const Enquiries = {
  sendEnquiry: (enquiry: FormValues) => requests.post("/enquiry/SendEnquiry", enquiry),
};

export default {
  Machineries,
  Enquiries,
};
