import styled from "styled-components";
import { grid, bp } from "../abstract/variables";

export const StyledGrid = styled.div`
  .row {
    max-width: ${grid.width};
    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: ${grid.gutterVertical};

      @media (max-width: ${bp.mobile}) {
        margin-bottom: 0;
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }

    [class^="col-"] {
      float: left;

      &:not(:last-child) {
        margin-right: ${grid.gutterHorizontal};
      }
    }

    .col-1-of-2 {
      width: calc((100% - ${grid.gutterHorizontal}) / 2);
      float: left;

      &:not(:last-child) {
        margin-right: ${grid.gutterHorizontal} - 0.1rem;
      }

      @media (max-width: ${bp.mobile}) {
        width: 100% !important;
        margin-bottom: 1.5rem;
      }
    }
  }
`;
