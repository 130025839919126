import React, { useState, useEffect, Fragment, useRef } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import Machinery from "../../../models/Machinery";
import agent from "../../../api/agent";
import { ContactUs } from "../../common/ContactUs/ContactUs";
import { StyledBreadcrumb } from "../../../styles/components/StyledBreadcrumb";
import { StyledMachineryPage } from "./StyledMachineryPage";
import { StyledButtonPrimay } from "../../../styles/components/StyledButton";
import { MachineryPagePlaceholder } from "../../common/MachineryPagePlaceholder/MachineryPagePlaceholder";
import { DisplayMobile } from "../../../styles/base/utilities";
import { smoothScrollTo, supportsNativeSmoothScroll } from "../../../services/SmoothScrollTo";
import { MachineryPageDetails } from "./MachineryPageDetails";

interface Params {
  machineryName: string;
}

export const MachineryPage: React.FC<RouteComponentProps<Params>> = ({ match, history }) => {
  const [machinery, setMachinery] = useState<Machinery>();
  const [loading, setLoading] = useState(true);

  const contactRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const scrollToContact = () => {
    if (supportsNativeSmoothScroll && contactRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: contactRef.current.offsetTop,
      });
    } else if (contactRef) {
      smoothScrollTo(contactRef.current.offsetTop, 200);
    } else {
      window.location.hash = "#contact-form";
    }
  };

  useEffect(() => {
    setLoading(true);
    agent.Machineries.machinery(match.params.machineryName)
      .then((response) => {
        setMachinery(response);
      })
      .then(() => setLoading(false))
      .catch(() => history.push("/notfound"));
  }, [match, history]);

  return (
    <StyledMachineryPage>
      <div className="machinery-page">
        {loading && !machinery ? (
          <div className="container">
            <MachineryPagePlaceholder />
          </div>
        ) : (
          machinery && (
            <Fragment>
              <StyledBreadcrumb>
                <div className="breadcrumb">
                  <div className="container">
                    <Link to={"/"} className="breadcrumb__item">
                      Home
                    </Link>
                    {machinery.subCategory.toLowerCase() !== machinery.type.toLowerCase() && (
                      <Link
                        to={`/machinery/${machinery.subCategory.replace(/\s/g, "")}`}
                        className="breadcrumb__item"
                      >
                        {machinery.subCategory}
                      </Link>
                    )}
                    <div className="breadcrumb__item active">{machinery.type}</div>
                  </div>
                </div>
              </StyledBreadcrumb>

              <DisplayMobile>
                <div className="u-display-mobile">
                  <Link
                    to={`/machinery/${machinery.subCategory.replace(/\s/g, "")}`}
                    className="back"
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i> Back
                  </Link>
                  <div className="title">{machinery.fullName}</div>
                </div>
              </DisplayMobile>

              <section className="brand">
                <div className="container">
                  <div className="brand__logo">
                    <img src={machinery.brand.imageUrl} alt={machinery.brand.name} />
                  </div>
                  <div className="brand__title">
                    {machinery.brand.name.toUpperCase()} / {machinery.category.toUpperCase()}
                  </div>
                  <div className="brand__description">
                    {machinery.brand.descriptions.map((d, i) => (
                      <p key={i}>{d}</p>
                    ))}
                  </div>
                </div>
              </section>

              <section className="description">
                <div className="container">
                  <div className="description__details">
                    <div className="description__details__ipad">
                      {machinery.subTitle && <span className="subtitle">{machinery.subTitle}</span>}
                      <span className="title">{machinery.title}</span>
                    </div>
                    <div className="description__img">
                      <img
                        className="description__img__img"
                        src={machinery.imageUrl}
                        alt={machinery.fullName}
                      />
                    </div>
                    <div className="description__content">
                      {machinery.subTitle && (
                        <div className="description__content__subtitle">{machinery.subTitle}</div>
                      )}
                      <div className="description__content__title">{machinery.title}</div>
                      <div className="description__content__details">
                        {machinery.details.map((d, i) => (
                          <p key={i}>{d}</p>
                        ))}
                      </div>
                      <div className="description__content__quote">
                        <StyledButtonPrimay onClick={scrollToContact}>
                          <button className="btn-primary description__quote">
                            Get a quote now
                          </button>
                        </StyledButtonPrimay>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <MachineryPageDetails machinery={machinery}></MachineryPageDetails>

              <section ref={contactRef} className="contact">
                <ContactUs machinery={machinery!} />
              </section>
            </Fragment>
          )
        )}
      </div>
    </StyledMachineryPage>
  );
};
