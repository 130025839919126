import React, { useState, useEffect } from "react";
import MachineryBySubCategory from "../../../models/MachineryBySubCategory";
import agent from "../../../api/agent";
import { HomeCardsPlaceholder } from "../../common/HomeCardsPlaceholder/HomeCardsPlaceholder";
import { Link, RouteComponentProps } from "react-router-dom";
import { typeToName } from "../../../models/TypeToName";
import { StyledHomePage } from "./StyledHomePage";
import { StyledCard } from "../../../styles/components/StyledCard";
import { DisplayMobile } from "../../../styles/base/utilities";
import { HeaderLeftbox } from "../../layouts/Header/HeaderLeftbox";
import { HeaderContent } from "../../layouts/Header/HeaderContent";

export const HomePage: React.FC<RouteComponentProps> = ({ history }) => {
  const [machineryBySubCategory, setMachineyBySubcategory] = useState<MachineryBySubCategory[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    agent.Machineries.machineryBySubCategory()
      .then((response) => {
        setMachineyBySubcategory(response);
      })
      .then(() => setLoading(false))
      .catch(() => history.push("/notfound"));
  }, [history]);

  return (
    <StyledHomePage>
      <div className="home-page">
        <DisplayMobile>
          <div className="u-display-mobile">
            <div className="header">
              <div className="header__top">
                <HeaderLeftbox isHome={true} />
              </div>
              <HeaderContent />
            </div>
          </div>
        </DisplayMobile>
        <div className="cards">
          {loading ? (
            <div>
              <HomeCardsPlaceholder />
              <HomeCardsPlaceholder />
            </div>
          ) : (
            <StyledCard>
              {machineryBySubCategory.map((machinery) => (
                <div key={machinery.subCategory} className="card">
                  <div className="card__header">
                    {machinery.brandName.toUpperCase()} / {machinery.category.toUpperCase()}
                  </div>
                  <Link
                    to={
                      machinery.types.length > 1
                        ? `machinery/${machinery.subCategory.replace(/\s/g, "")}`
                        : `machinery/${machinery.subCategory.replace(/\s/g, "")}/${
                            typeToName[machinery.types[0]]
                          }`
                    }
                  >
                    <div className="card__title">{machinery.subCategory}</div>
                  </Link>
                  <Link
                    className="card__link__image"
                    to={
                      machinery.types.length > 1
                        ? `machinery/${machinery.subCategory.replace(/\s/g, "")}`
                        : `machinery/${machinery.subCategory.replace(/\s/g, "")}/${
                            typeToName[machinery.types[0]]
                          }`
                    }
                  >
                    <img
                      src={machinery.subCategoryImageUrl}
                      alt={machinery.subCategory}
                      className={`card__image card__image--${machinery.subCategory.replace(
                        /\s/g,
                        "",
                      )}`}
                    />
                  </Link>
                  <div className="card__types">
                    {machinery.types.map((t) => (
                      <Link
                        key={t}
                        to={`machinery/${machinery.subCategory.replace(/\s/g, "")}/${
                          typeToName[t]
                        }`}
                        className="card__type"
                      >
                        {t}
                      </Link>
                    ))}
                  </div>
                  <img
                    src={machinery.brandImage}
                    alt={machinery.brandName}
                    className="card__logo"
                  />
                </div>
              ))}
            </StyledCard>
          )}
        </div>
      </div>
    </StyledHomePage>
  );
};
